import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import PageTitle from '../components/pagetitle/PageTitle';
import data from '../assets/fake-data/data-blog'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { WebsiteUrl } from '../App';
import { truncate } from "lodash";
import { Helmet } from "react-helmet";
import '../css/Blog02.css'
Blog01.propTypes = {

};

function Blog01(props) {

    const [data, setdata] = useState([])
    const [data2, setdata2] = useState([])

    const [currentPage, setCurrentPage] = useState(1);

    const postsPerPage = 4;

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        axios
            .get(`${global.backendUrl}/knowledge/get`)
            .then((res) => setdata(res.data))
            .catch((error) => console.log(error));

        axios.get(`${global.backendUrl}/homeKnowledgeSco/get`)
            .then((response) => {
                setdata2(response.data)
            }).catch((err) => {
                console.log(err)
            })
    }, []);
    return (
        <>
            {
                data2[0] && (
                    <Helmet>
                        <title>{data2[0]?.meta_title}</title>

                        <meta
                            name="description"
                            content={data2[0]?.meta_description}
                        />
                        <meta
                            name="keywords"
                            content={JSON.parse(data2[0]?.meta_keywords)}
                        />
                        {/* <link rel="canonical" href={`${WebsiteUrl}`} /> */}
                        {/* <link
                            rel="canonical"
                            href={`${WebsiteUrl}`}
                        /> */}
                        <meta name="robots" content="index,follow" />
                        <meta property="og:title" content={data2[0]?.meta_title} />
                        <meta
                            property="og:description"
                            content={data2[0]?.meta_description}
                        />
                        <meta
                            property="og:image"
                            content={`${global.backendUrl}/nodeassets/${data2[0]?.meta_img}`}
                        />
                        {/* <meta property="og:url" content={`${WebsiteUrl}`} /> */}
                        {/* <meta
                            property="og:url"
                            content={`${WebsiteUrl}`}
                        /> */}
                        <meta property="og:type" content="website" />
                        <meta name="twitter:card" content={data2[0]?.meta_title} />
                        <meta name="twitter:title" content={data2[0]?.meta_title} />
                        <meta
                            name="twitter:description"
                            content={data2[0]?.meta_description}
                        />

                        <meta name="viewport" content="width=device-width, initial-scale=1" />
                        <link rel="favicon" href="/favicon.ico" />
                        <meta
                            name="twitter:image"
                            content={`${global.backendUrl}/nodeassets/${data2[0]?.meta_img}`}
                        />
                    </Helmet>
                )
            }

            <PageTitle sub='Knowledge' title='Knowledge' />


            <section className="tf-blog">
                <div className="tf-container">
                    <div className="row">
                        {
                            currentPosts.map(idx => (
                                <div key={idx.id} className="col-md-6">
                                    <article className="tf-blog-item" style={{ height: "550px" }}>
                                        <div className="image">
                                            {/* <Link to="/blog-details-v1"><img src={idx.img}  alt="Binasea" /></Link> */}
                                            <iframe src={idx.VideoLink} style={{ height: "350px" }} width={"100%"} title="Iframe Example"></iframe>
                                        </div>

                                        <div className="title">
                                            <h5><Link to="#">{truncate(idx.title, { length: 80 })}</Link></h5>
                                            {/* <Link to="/blog-details-v1" className="tf-button style-1"><span>{idx.cate}</span></Link> */}
                                        </div>
                                        {/* <p className="content">{idx.text}</p> */}

                                        {/* <div className="meta">
                                            <span className="admin"> <i className="fal fa-user"></i>
                                                {idx.user}
                                            </span>
                                            <span className="date"><i className="far fa-clock"></i>{idx.time}</span>
                                        </div> */}
                                    </article>
                                </div>
                            ))
                        }
                        {/* <div className="col-md-12 ">
                            <div className="load-more">
                                <a className="tf-button loadmore" href="#">Load more</a>
                            </div>
                        </div> */}

                    </div>
                    <ul className="pagination">
                        {Array.from({ length: Math.ceil(data.length / postsPerPage) }).map((_, index) => (
                            <li key={index} className="page-item">
                                <button onClick={() => paginate(index + 1)} className="page-link">
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>

        </>
    );
}

export default Blog01;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import icon1 from '../assets/images/svg/icon-create-5.svg'
import icon2 from '../assets/images/svg/icon-create-6.svg'
import icon3 from '../assets/images/svg/icon-create-3.svg'
import icon4 from '../assets/images/svg/icon-create-7.svg'
import icon5 from '../assets/images/svg/icon-create-8.svg'
import icon6 from '../assets/images/svg/icon-create-9.svg'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { truncate } from "lodash";


HelpCenter.propTypes = {

};

function HelpCenter(props) {
    const [data, setdata] = useState([])
    const [data2, setdata2] = useState([])

    const [expandedItems, setExpandedItems] = useState([]);

    const toggleExpand = (id) => {
        if (expandedItems.includes(id)) {
            setExpandedItems(expandedItems.filter((item) => item !== id));
        } else {
            setExpandedItems([...expandedItems, id]);
        }
    };

    useEffect(() => {
        axios
            .get(`${global.backendUrl}/howworkstitle`)
            .then((res) => {
                setdata(res?.data);
            })
            .catch((err) => console.log(err));

        axios
            .get(`${global.backendUrl}/howworkssection`)
            .then((res) => {
                setdata2(res?.data);
            })
            .catch((err) => console.log(err));
    }, [])



    const [dataHelp] = useState([
        {
            id: 1,
            icon: icon1,
            title: 'Getting Started',
            text: 'Learn how to create an account, set up your wallet, and what you can do on BinaSea',
            link: '#'
        },
        {
            id: 2,
            icon: icon2,
            title: 'Creating',
            text: 'Learn how to create an account, set up your wallet, and what you can do on BinaSea',
            link: '#'
        },
        {
            id: 3,
            icon: icon3,
            title: 'Buying',
            text: 'Learn how to create an account, set up your wallet, and what you can do on BinaSea',
            link: '#'
        },
        {
            id: 4,
            icon: icon4,
            title: 'Selling',
            text: 'Learn how to create an account, set up your wallet, and what you can do on BinaSea',
            link: '#'
        },
        {
            id: 5,
            icon: icon5,
            title: 'Partner',
            text: 'Learn how to create an account, set up your wallet, and what you can do on BinaSea',
            link: '#'
        },
        {
            id: 6,
            icon: icon6,
            title: 'Developers',
            text: 'Learn how to create an account, set up your wallet, and what you can do on BinaSea',
            link: '#'
        },
    ])
    return (
        <div>
            <section className="tf-page-title style-2">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">

                            <ul className="breadcrumbs">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Pages</Link></li>
                                <li>How It Works</li>
                            </ul>

                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-help-center">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            {/* <div className="tf-heading style-5">
                                <h4 className="heading">How Can We Help You?</h4>
                                <p className="sub-heading">Lorem Ipsum Dolor Sit Amet, Consectetur Adipisicing Elit. Laborum Obcaecati Dignissimos Quae Quo Ad Iste Ipsum Officiis Deleniti Asperiores Sit. </p>
                            </div> */}

                            {/* <form action="#" className="help-center-form">
                                <input type="text" placeholder="Enter Question..." required="" />
                                <button><i className="fas fa-search"></i></button>
                            </form> */}
                            <p className='text-center' style={{ textTransform: 'uppercase', fontWeight: 'bold', color: "#3649E9" }}>{data[0]?.howworks_tag}</p>
                            <h4 className="heading-help tf-heading style-5">{data[0]?.howworks_title}</h4>

                            <div className="tf-create-wrap">
                                {data2.map((item) => (
                                    <div key={item.id} className="tf-create style-2">
                                        <div className="icon">
                                            <img
                                                src={`${global.backendUrl}/nodeassets/${item.howworks_image}`}
                                                alt="Binasea"
                                            />
                                        </div>
                                        <h6 className="title">{item.howworks_title}</h6>
                                        <p className="content" style={{ textAlign: 'start' }}>
                                            {expandedItems.includes(item.id) ? item.howworks_text : truncate(item.howworks_text, { length: 200 })}
                                        </p>
                                        <div className="button">
                                            <div className="tf-button" onClick={() => toggleExpand(item.id)}>
                                                {expandedItems.includes(item.id) ? 'View less' : 'View more'}
                                                <i className="fal fa-long-arrow-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}

export default HelpCenter;
{/* <div className="tf-create-wrap">
{
    data2.map((idx,i) => (
        <div key={idx.id} className="tf-create style-2">
            <div className="icon">
                <img src={'https://binaseareact.vercel.app/static/media/icon-create-5.a239fe63e633e5bcaf9da315c8bbb3c0.svg'} alt="Binasea" />
            </div>
            <h6 className="title">{idx.howworks_title}</h6>
            <p className="content" style={{textAlign:'start'}}>{truncate(idx.howworks_text, { length: 200 })}</p>
            <div className="button">
                <div className="tf-button">View more<i className="fal fa-long-arrow-right"></i></div>
            </div>
        </div>
    ))
}
</div> */}

import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../components/context/AppContext';
export default function Otp() {
    const { setisAuthenticated } = useStateContext();
    const navigate = useNavigate();
    const location = useLocation();
    const [userOtp, setuserOtp] = useState('')
    const [isLoading, setisLoading] = useState(false)

    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        // Remove any non-numeric characters
        const numericValue = inputValue.replace(/\D/g, '');

        // Limit the length to 4 characters
        const truncatedValue = numericValue.slice(0, 4);

        setuserOtp(truncatedValue);
    };

    const handleContinue = () => {
        if (location?.state?.dataObj?.otp == userOtp) {
            setisLoading(true)
            let formdata = {
                email: location?.state?.dataObj?.email,
                password: location?.state?.dataObj?.password,
                role: "user",
                name: location?.state?.dataObj?.name
            }
            axios.post(`${global.backendUrl}/users/insert`, formdata)
                .then((res) => {
                    toast.success(`Signed Up Successfully`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setisLoading(false)
                    var user = {
                        email: location?.state?.dataObj?.email,
                        uid: res.data.insertId,
                        role: 'user',
                        name: location?.state?.dataObj?.name
                    }
                    localStorage.setItem("antsInformation", JSON.stringify(user))
                    setisAuthenticated(true)
                    navigate('/dashboard')
                }).catch((err) => {
                    console.log(err)
                    setisLoading(false)
                })
        } else {
            toast.error(`Incorrect OTP`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    return (
        <div>
            <section className="tf-page-title style-2">
                <div className="tf-container">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="breadcrumbs">
                                <li><Link to="/blog-v2">Home</Link></li>
                                <li><Link to="/signup">Sign Up</Link></li>
                                <li>Otp</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-login">
                <div className="tf-container">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="tf-heading style-2" style={{ gap: "10px" }}>
                                <h4 className="heading">Enter OTP</h4>
                                <p className='mt-4'>(Sended on you email)</p>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-9 col-md-12">
                            {/* <form action="#" id="contactform"> */}
                            <fieldset><input id="name" name="name" tabindex="1" aria-required="true" required="" type="text" placeholder="Enter Your Otp Here" value={userOtp} onChange={handleInputChange} /></fieldset>
                            <button className="submit" onClick={handleContinue} disabled={isLoading}>
                                {
                                    isLoading ? "Loading..." : 'Continue'
                                }
                            </button>
                            <div className='mt-5 text-center'>Wanna Go Back? <span style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={() => navigate('/signup')}>SignUp</span></div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

import avt1 from '../images/avtimg.png';

const dataLiveaution = [
    {
        id: 1,
        img: "https://binaseareact.vercel.app/static/media/product1.e4b29487c24fedf3fb6c.jpg",
        title: 'Giulia Glur #32',
        create: 'Magnus Perry',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 2,
        img: "https://binaseareact.vercel.app/static/media/product27.5090bb8405495b6a44ca.jpg",
        title: 'Giulia Glur #37',
        create: 'Frederick Dixon',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 3,
        img: "https://binaseareact.vercel.app/static/media/product28.a7b5ef3c6b3c2e2ea335.jpg",
        title: 'Archetype #588',
        create: 'Theo Watts',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 4,
        img: "https://binaseareact.vercel.app/static/media/product29.bf9d392790f399ec98b2.jpg",
        title: '3DPunks #070',
        create: 'Neville Gutierrez',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 5,
        img: "https://binaseareact.vercel.app/static/media/product1.e4b29487c24fedf3fb6c.jpg",
        title: 'Angelic Nyan Cat',
        create: 'Frederick Dixon',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 6,
        img: "https://binaseareact.vercel.app/static/media/product27.5090bb8405495b6a44ca.jpg",
        title: 'Sweet Baby #1',
        create: 'Stephan Neal',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 7,
        img: "https://binaseareact.vercel.app/static/media/product28.a7b5ef3c6b3c2e2ea335.jpg",
        title: 'Giulia Glur #32',
        create: 'Magnus Perry',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 8,
        img: "https://binaseareact.vercel.app/static/media/product29.bf9d392790f399ec98b2.jpg",
        title: 'Giulia Glur #37',
        create: 'Frederick Dixon',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 9,
        img: "https://binaseareact.vercel.app/static/media/product1.e4b29487c24fedf3fb6c.jpg",
        title: 'Archetype #588',
        create: 'Theo Watts',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 10,
        img: "https://binaseareact.vercel.app/static/media/product29.bf9d392790f399ec98b2.jpg",
        title: '3DPunks #070',
        create: 'Neville Gutierrez',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 11,
        img: "https://binaseareact.vercel.app/static/media/product28.a7b5ef3c6b3c2e2ea335.jpg",
        title: 'Angelic Nyan Cat',
        create: 'Frederick Dixon',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 12,
        img: "https://binaseareact.vercel.app/static/media/product29.bf9d392790f399ec98b2.jpg",
        title: 'Sweet Baby #1',
        create: 'Stephan Neal',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 13,
        img: "https://binaseareact.vercel.app/static/media/product28.a7b5ef3c6b3c2e2ea335.jpg",
        title: 'Angelic Nyan Cat',
        create: 'Frederick Dixon',
        price : '5 ETH',
        avt: avt1,
    },
    {
        id: 14,
        img: "https://binaseareact.vercel.app/static/media/product29.bf9d392790f399ec98b2.jpg",
        title: 'Sweet Baby #1',
        create: 'Stephan Neal',
        price : '5 ETH',
        avt: avt1,
    },

    
   
]

export default dataLiveaution;
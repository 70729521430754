import seller from "../images/seller.png";

const dataSeller = [
  {
    id: 1,
    avt: seller,
    price: "1.56 wETH",
    name: "Roxanne Wallaker",
  },
  {
    id: 2,
    avt: seller,
    price: "1.56 wETH",
    name: "Winifred Clem",
  },
  {
    id: 3,
    avt: seller,
    price: "1.56 wETH",
    name: "Angelica Sherman",
  },
  {
    id: 4,
    avt: seller,
    price: "1.56 wETH",
    name: "Hayden Yates",
  },
  {
    id: 5,
    avt: seller,
    price: "1.56 wETH",
    name: "Rowena Tillery",
  },
  {
    id: 6,
    avt: seller,
    price: "1.56 wETH",
    name: "Roderick Norman",
  },
  {
    id: 7,
    avt: seller,
    price: "1.56 wETH",
    name: "Virginia Richards",
  },
  {
    id: 8,
    avt: seller,
    price: "1.56 wETH",
    name: "Barbara Moore",
  },
  {
    id: 9,
    avt: seller,
    price: "1.56 wETH",
    name: "Virginia Richards",
  },
  {
    id: 10,
    avt: seller,
    price: "1.56 wETH",
    name: "Colin Fennimore",
  },
  {
    id: 11,
    avt: seller,
    price: "1.56 wETH",
    name: "Ebenezer Anderson",
  },
  {
    id: 12,
    avt: seller,
    price: "1.56 wETH",
    name: "Simona Davidson",
  },

  {
    id: 13,
    avt: seller,
    price: "100 ETH",
    name: "Wren Clark",
    numb: "1",
  },
  {
    id: 14,
    avt: seller,
    price: "90 ETH",
    name: "Madge Dennis",
    numb: "2",
  },
  {
    id: 15,
    avt: seller,
    price: "80 ETH",
    name: "Alfred Mitchell",
    numb: "3",
  },
  {
    id: 16,
    avt: seller,
    price: "70 ETH",
    name: "Wren Clark",
    numb: "4",
  },
  {
    id: 17,
    avt: seller,
    price: "60 ETH",
    name: "Troy Sharp",
    numb: "5",
  },
  {
    id: 18,
    avt: seller,
    price: "50 ETH",
    name: "Len Davis",
    numb: "6",
  },
];

export default dataSeller;

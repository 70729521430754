

import avt1 from '../images/avtimg.png';

const dataBanner = [
    {
        id: 1,
        
        heading: 'Defind, Collect and Sell Super Rate NFT ',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        img: "https://binaseareact.vercel.app/static/media/slider-1.659aee2f403e034a0ec9.jpg",
        price: '1.56 wETH',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },
    {
        id: 2,
        
        heading: 'Defind, Collect and Sell Super Rate NFT ',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        img: "https://binaseareact.vercel.app/static/media/slider-1.659aee2f403e034a0ec9.jpg",
        price: '1.56 wETH',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },
    {
        id: 3,
        
        heading: 'Defind, Collect And Sell Super Rate NFT ',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        img: "https://binaseareact.vercel.app/static/media/slider-1.659aee2f403e034a0ec9.jpg",
        price: '1.56 wETH',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },
    {
        id: 4,
        
        heading: 'Defind, Collect and Sell Super Rate NFT ',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio. Praesent libero. Sed cursus ante dapibus diam.',
        img: "https://binaseareact.vercel.app/static/media/slider-1.659aee2f403e034a0ec9.jpg",
        price: '1.56 wETH',
        avt: avt1,
        name: '“The Monkey sad ”',
        tag: '@SolvadorDali'
    },

    
    

]

export default dataBanner;
import avt from "../images/avtimg.png";
const dataHotpick = [
  {
    id: 1,
    img: "https://binaseareact.vercel.app/static/media/product2.0b6ec08f91d853ee035a.jpg",
    avt: avt,
    title: "Giulia Glur #32",
    create: "Magnus Perry",
    price: "0.0041",
  },
  {
    id: 2,
    img: "https://binaseareact.vercel.app/static/media/product32.81d8ef00900e9a72abfa.jpg",
    avt: avt,
    title: "Giulia Glur #32",
    create: "Frederick Dixon",
    price: "0.0041",
  },
  {
    id: 3,
    img: "https://binaseareact.vercel.app/static/media/product33.63b83708478f82086a98.jpg",
    avt: avt,
    title: "Archetype #588",
    create: "Theo Watts",
    price: "0.0041",
  },
  {
    id: 4,
    img: "https://binaseareact.vercel.app/static/media/product34.2e5353c7acf84baefaf2.jpg",
    avt: avt,
    title: "3DPunks #070",
    create: "Neville Gutierrez",
    price: "0.0041",
  },
  {
    id: 5,
    img: "https://binaseareact.vercel.app/static/media/product35.e2b9482ff48a6306d208.jpg",
    avt: avt,
    title: "Angelic Nyan Cat",
    create: "Frederick Dixon",
    price: "0.0041",
  },
  {
    id: 6,
    img: "https://binaseareact.vercel.app/static/media/product36.fb88d1cf3bc548f57908.jpg",
    avt: avt,
    title: "Sweet Baby #1",
    create: "Stephan Neal",
    price: "0.0041",
  },

  {
    id: 7,
    img: "https://binaseareact.vercel.app/static/media/product37.cdbb4c3aaeb2245120d1.jpg",
    avt: avt,
    title: "Giulia Glur #32",
    create: "Magnus Perry",
    price: "0.0041",
  },
  {
    id: 8,
    img: "https://binaseareact.vercel.app/static/media/product38.defd0871575df1192ba9.jpg",
    avt: avt,
    title: "Giulia Glur #32",
    create: "Frederick Dixon",
    price: "0.0041",
  },

  {
    id: 9,
    img: "https://binaseareact.vercel.app/static/media/product39.9798edb40143118941df.jpg",
    avt: avt,
    title: "Giulia Glur #32",
    create: "Magnus Perry",
    price: "0.0041",
  },
  {
    id: 10,
    img: "https://binaseareact.vercel.app/static/media/product40.da8195521b7effb1c233.jpg",
    avt: avt,
    title: "Giulia Glur #32",
    create: "Frederick Dixon",
    price: "0.0041",
  },
  {
    id: 11,
    img: "https://binaseareact.vercel.app/static/media/product41.517591dcd3dbf577e06c.jpg",
    avt: avt,
    title: "Archetype #588",
    create: "Theo Watts",
    price: "0.0041",
  },
  {
    id: 12,
    img: "https://binaseareact.vercel.app/static/media/product42.da2db05b8cd34e5c2081.jpg",
    avt: avt,
    title: "3DPunks #070",
    create: "Neville Gutierrez",
    price: "0.0041",
  },
];

export default dataHotpick;

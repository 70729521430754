import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
export default function AccountSetting({ data, fetchData }) {
    const navigate = useNavigate();

    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [fb, setfb] = useState('')
    const [twitter, settwitter] = useState('')
    const [linkedin, setlinkedin] = useState('')
    const [discord, setdiscord] = useState('')
    const [medium, setmedium] = useState('')
    const [reddit, setreddit] = useState('')
    const [website, setwebsite] = useState('')
    const [uid, setuid] = useState('')

    const [imgFile, setimgFile] = useState("")
    const [featureImage, setfeatureImage] = useState('')
    const [coverImage, setcoverImage] = useState('')

    // const [imageURLS, setimageURLS] = useState()
    const [profileUrl, setprofileUrl] = useState('')
    const [featureUrl, setfeatureUrl] = useState('')
    const [coverUrl, setcoverUrl] = useState('')

    const [isLoading, setisLoading] = useState(false)

    const handleProfile = (e) => {
        setimgFile(e.target.files[0])
    }
    const handleFeature = (e) => {
        setfeatureImage(e.target.files[0])
    }
    const handleCover = (e) => {
        setcoverImage(e.target.files[0])
    }

    // const handleSave = () => {
    //     if (!uid) { return showError("Login Again! There is an error") };
    //     if (!name) { return showError("Enter The User Name") };
    //     if (!email) { return showError("Enter Your Email") };
    //     if (!password) { return showError("Enter Your Password") };


    //     if (imgFile instanceof File) {
    //         if (!(featureImage instanceof File)) { return showError("Select The Feature Image") };
    //         if (!(coverImage instanceof File)) { return showError("Select The Cover Image") };
    //     }
    //     if (featureImage instanceof File) {
    //         if (!(imgFile instanceof File)) { return showError("Select The Profile Pic") };
    //         if (!(coverImage instanceof File)) { return showError("Select The Cover Image") };
    //     }
    //     if (coverImage instanceof File) {
    //         if (!(featureImage instanceof File)) { return showError("Select The Feature Image") };
    //         if (!(imgFile instanceof File)) { return showError("Select The Profile Pic") };
    //     }



    //     function showError(fieldName) {
    //         toast.error(`Please ${fieldName}`, {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });
    //     }

    //     setisLoading(true)

    //     const formData = new FormData();
    //     formData.append("name", name);
    //     formData.append("email", email);
    //     formData.append("password", password);
    //     formData.append("link_fb", fb);
    //     formData.append("link_twitter", twitter);
    //     formData.append("link_linkedin", linkedin);
    //     formData.append("link_discord", discord);
    //     formData.append("link_medium", medium);
    //     formData.append("link_reddit", reddit);
    //     formData.append("link_website", website);
    //     formData.append("userImages", imgFile);
    //     formData.append("userImages", featureImage);
    //     formData.append("userImages", coverImage);

    //     axios.put(`${global.backendUrl}/signup/update/${uid}`, formData)
    //         .then((response) => {
    //             toast.success("Profile Updated Successfully", {
    //                 position: "top-right",
    //                 autoClose: 3000,
    //                 hideProgressBar: false,
    //                 closeOnClick: true,
    //                 pauseOnHover: true,
    //                 draggable: true,
    //                 progress: undefined,
    //             });
    //             setisLoading(false)
    //             fetchData()
    //             if(imgFile instanceof File && featureImage instanceof File && coverImage instanceof File){
    //                 if(imageURLS){
    //                     const fileUrls = imageURLS;
    //                     const formData = {fileUrls}
    //                     axios.post(`${global.backendUrl}/deleteRemoteFile`, formData)
    //                         .then((response) => {
    //                             console.log(response)
    //                         })
    //                         .catch((error) => {
    //                             console.log(error)
    //                         })
    //                 }
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //             setisLoading(false)
    //         })
    // }

    const handleSave = () => {
        if (!uid) { return showError("Login Again! There is an error") };
        if (!name) { return showError("Enter The User Name") };
        if (!email) { return showError("Enter Your Email") };
        if (!password) { return showError("Enter Your Password") };

        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setisLoading(true)

        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("link_fb", fb);
        formData.append("link_twitter", twitter);
        formData.append("link_linkedin", linkedin);
        formData.append("link_discord", discord);
        formData.append("link_medium", medium);
        formData.append("link_reddit", reddit);
        formData.append("link_website", website);
        formData.append("profile", imgFile);
        formData.append("feature", featureImage);
        formData.append("cover", coverImage);

        axios.put(`${global.backendUrl}/signup/update/${uid}`, formData)
            .then((response) => {
                toast.success("Profile Updated Successfully", {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setisLoading(false)
                fetchData()
                let fileUrls = []
                if (imgFile instanceof File) {
                    if (profileUrl) {
                        fileUrls.push(profileUrl)
                    }
                }
                if (featureImage instanceof File) {
                    if (featureUrl) {
                        fileUrls.push(featureUrl)
                    }
                }
                if (coverImage instanceof File) {
                    if (coverUrl) {
                        fileUrls.push(coverUrl)
                    }
                }
                if (fileUrls.length !== 0) {
                    const formData = { fileUrls }
                    axios.post(`${global.backendUrl}/deleteRemoteFile`, formData)
                        .then((response) => {
                            console.log(response)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            })
            .catch((error) => {
                console.log(error)
                setisLoading(false)
            })
    }

    useEffect(() => {
        setname(data?.name)
        setemail(data?.email)
        setpassword(data?.password)
        setuid(data?.uid)
        setfb(data?.link_fb)
        settwitter(data?.link_twitter)
        setlinkedin(data?.link_linkedin)
        setdiscord(data?.link_discord)
        setmedium(data?.link_medium)
        setreddit(data?.link_reddit)
        setwebsite(data?.link_website)
        if (data?.image) {
            setimgFile(`${global.backendUrl}/nodeassets/${data?.image}`)
        }
        if (data?.featured_img) {
            setfeatureImage(`${global.backendUrl}/nodeassets/${data?.featured_img}`)
        }
        if (data?.cover_img) {
            setcoverImage(`${global.backendUrl}/nodeassets/${data?.cover_img}`)
        }
        if (data?.image) {
            // setimageURLS([data?.image,data?.featured_img,data?.cover_img])
            setprofileUrl(data?.image)
        }
        if (data?.featured_img) {
            setfeatureUrl(data?.featured_img)
        }
        if (data?.cover_img) {
            setcoverUrl(data?.cover_img)
        }
    }, [data])

    // const handleDelete = () => {
    //     const fileUrls = [
    //         '1696506505762--auth.PNG',
    //         '1696506505761--stripe.PNG',
    //       ];
    //     const formData = {fileUrls}
    //     axios.post(`${global.backendUrl}/deleteRemoteFile`, formData)
    //         .then((response) => {
    //             console.log(response)
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //         })
    // }

    return (
        <div className="form-edit-profile">
            <div className="user-profile">
                <div className="title">Contact details</div>
                <fieldset>
                    <h6>User Name</h6>
                    <input
                        type="text"
                        placeholder="Francisco Maia"
                        required
                        value={name}
                        onChange={e => setname(e.target.value)}
                    />
                </fieldset>
                <fieldset>
                    <h6>Email Address</h6>
                    <input
                        type="text"
                        placeholder="Francisco Maia"
                        disabled
                        required
                        value={email}
                        onChange={e => setemail(e.target.value)}
                    />
                </fieldset>
                <fieldset>
                    <h6>Password</h6>
                    <input
                        type="password"
                        placeholder="****"
                        required
                        value={password}
                        onChange={e => setpassword(e.target.value)}
                    />
                </fieldset>

                <div className="title mt-5">Social Links</div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <fieldset>
                            <h6>Facebook</h6>
                            <input type="text" value={fb} onChange={e => setfb(e.target.value)} placeholder="Your facebook link" required />
                        </fieldset>
                    </div>
                    <div className='col-lg-6'>
                        <fieldset>
                            <h6>Twitter</h6>
                            <input type="text" value={twitter} onChange={e => settwitter(e.target.value)} placeholder="Your twitter link" required />
                        </fieldset>
                    </div>
                    <div className='col-lg-6'>
                        <fieldset>
                            <h6>LinkedIn</h6>
                            <input type="text" value={linkedin} onChange={e => setlinkedin(e.target.value)} placeholder="Your linkedin link" required />
                        </fieldset>
                    </div>
                    <div className='col-lg-6'>
                        <fieldset>
                            <h6>Discord</h6>
                            <input type="text" value={discord} onChange={e => setdiscord(e.target.value)} placeholder="Your discord link" required />
                        </fieldset>
                    </div>
                    <div className='col-lg-6'>
                        <fieldset>
                            <h6>Medium</h6>
                            <input type="text" value={medium} onChange={e => setmedium(e.target.value)} placeholder="Your medium link" required />
                        </fieldset>
                    </div>
                    <div className='col-lg-6'>
                        <fieldset>
                            <h6>Reddit</h6>
                            <input type="text" value={reddit} onChange={e => setreddit(e.target.value)} placeholder="Your reddit link" required />
                        </fieldset>
                    </div>
                    <div className='col-lg-12'>
                        <fieldset>
                            <h6>Website</h6>
                            <input
                                type="text"
                                placeholder="Your website link"
                                required
                                value={website} onChange={e => setwebsite(e.target.value)}
                            />
                        </fieldset>
                    </div>
                </div>

                <div className="title mt-5">Images</div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <fieldset>
                            <h6>Profile Picture</h6>
                            {
                                imgFile && <img src={imgFile instanceof File ? URL.createObjectURL(imgFile) : imgFile} style={{ borderRadius: "10px", marginBottom: "10px", width: "120px", height: "120px" }} />
                            }
                            <input type="file" className='w-100' placeholder="Paste Here" onChange={handleProfile} required />
                        </fieldset>
                    </div>
                    <div className='col-lg-6'>
                        <fieldset>
                            <h6>Featured Image</h6>
                            {
                                featureImage && <img src={featureImage instanceof File ? URL.createObjectURL(featureImage) : featureImage} style={{ borderRadius: "10px", marginBottom: "10px", width: "260px", height: "160px" }} />
                            }
                            <input type="file" className='w-100' placeholder="Paste Here" onChange={handleFeature} required />
                        </fieldset>
                    </div>
                    <div className='col-lg-12'>
                        <fieldset>
                            <h6>Cover Picture</h6>
                            {
                                coverImage && <img src={coverImage instanceof File ? URL.createObjectURL(coverImage) : coverImage} style={{ borderRadius: "10px", marginBottom: "10px", width: "100%", height: "360px" }} />
                            }
                            <input type="file" className='w-100' placeholder="Paste Here" onChange={handleCover} required />
                        </fieldset>
                    </div>
                </div>
            </div>
            <button className="btn-form" onClick={handleSave} disabled={isLoading}>
                {
                    isLoading ? 'Loading...' : 'Update Profile'
                }
            </button>

            {/* <button className="btn-form" onClick={handleDelete}>
                delete
            </button> */}
        </div>
    )
}

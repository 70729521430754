import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const NgoModal = (props) => {
    const [amount, setamount] = useState()
    const [isLoading, setisLoading] = useState(false)
    const [data, setdata] = useState([])

    const day = new Date().getDate();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    const date = month + "/" + day + "/" + year;
    const [monthlyDeadline, setmonthlyDeadline] = useState('')

    const handleContinue = () => {
        if (!props.userId) { return showError("login again! There is an error") };
        if (!amount) { return showError("enter the amount") };



        function showError(fieldName) {
            toast.error(`Please ${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        const filter = data?.filter((t) => (t.uid == props.userId) && (t.project_id == props.projectId))

        if (filter.length == 0) {
            setisLoading(true)
            let renewDate 
            if(props.donationType == 'monthly'){renewDate = monthlyDeadline}else{renewDate = null}
            const formData = { project_id: props.projectId, uid: props.userId, donation_amount: amount, donation_type: props.donationType, donation_date: date, donation_renew_date: renewDate }
            axios.post(`${global.backendUrl}/donation/insert`, formData)
                .then((response) => {
                    toast.success(`Donation Sent Successfully`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setamount("")
                    setisLoading(false)
                    props.onHide()
                })
                .catch((error) => {
                    console.log(error)
                    setisLoading(false)
                })
        } 
        else {
            setisLoading(true)
            let renewDate 
            if(props.donationType == 'monthly'){renewDate = monthlyDeadline}else{renewDate = null}
            const newAmount = parseInt(amount) + parseInt(filter[0].donation_amount)
            const formData = { donation_amount: newAmount, donation_type: props.donationType, donation_date: date, donation_renew_date: renewDate  }
            axios.put(`${global.backendUrl}/donation/update/${filter[0].donation_id}`, formData)
                .then((response) => {
                    toast.success(`Donation Sent Successfully`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setamount("")
                    setisLoading(false)
                    props.onHide()
                })
                .catch((error) => {
                    console.log(error)
                    setisLoading(false)
                })
        }
    }

    const fetchData = () => {
        axios.get(`${global.backendUrl}/donation/get`)
            .then((res) => {
                setdata(res.data)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchData();
    }, [props]);

    const handleAmountChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        if (inputValue < 0) {
            setamount(0);
        } else {
            setamount(inputValue);
        }
    };

    useEffect(() => {
        let nextMonth, nextYear;
        if (month === 11) {
            nextMonth = 1;
            nextYear = year + 1;
        } else {
            nextMonth = month + 1;
            nextYear = year;
        }
        const monthdead = nextMonth + "/" + day + "/" + nextYear;
        setmonthlyDeadline(monthdead);
    }, [])


    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
        >
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body space-y-20 pd-40">
                <h3>Donate Here</h3>
                <input type="number" value={amount} onChange={handleAmountChange} className="form-control mb-5" placeholder="Enter amount here in dollar" />
                <button className="button-popup w-100" disabled={isLoading} onClick={handleContinue}>Continue</button>
            </div>
        </Modal>

    );
};

export default NgoModal;


const dataCategory = [
    {
        id: 1,
        img: "https://binaseareact.vercel.app/static/media/product-category-5.1c65e990e476ee79f883.jpg",
        title: 'Digital art',
    },
    {
        id: 2,
        img: "https://binaseareact.vercel.app/static/media/product-category-1.d5b8b774d37a5eba57e3.jpg",
        title: 'Style',
    },
    {
        id: 3,
        img: "https://binaseareact.vercel.app/static/media/product-category-2.641b81be619cd538a455.jpg",
        title: 'Music',
    },
    {
        id: 4,
        img: "https://binaseareact.vercel.app/static/media/product-category-3.00f8ba2018faf1478044.jpg",
        title: 'Sport',
    },
    {
        id: 5,
        img: "https://binaseareact.vercel.app/static/media/product-category-5.1c65e990e476ee79f883.jpg",
        title: 'Digital art',
    },

   
]

export default dataCategory;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "./styles.scss";
import { avatarimg, beesimg, heroTextBg } from "../../utils/Assets";
import { useStateContext } from "../context/AppContext";

function Banner01() {
  const { isAuthenticated } = useStateContext()
  const [data, setdata] = useState([])
  const [data2, setdata2] = useState('')
  const [whitePaper, setwhitePaper] = useState("")

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDownload = () => {
    const fileUrl = `${global.backendUrl}/nodeassets/${whitePaper}`;
    window.open(fileUrl, '_blank');
  };

  useEffect(() => {
    axios
      .get(`${global.backendUrl}/herosection`)
      .then((res) => {
        setdata(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${global.backendUrl}/howtovote/get`)
      .then((res) => {
        setdata2(res?.data[0].htv_VideoLink);
      })
      .catch((err) => console.log(err));

    axios
      .get(`${global.backendUrl}/whitePaper/get`)
      .then((res) => {
        setwhitePaper(res?.data[0].wp_pdf)
      })
      .catch((err) => console.log(err));
  }, [])


  return (
    <section className="tf-slider">
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="slider-home home1">
              <div className="tf-slider-item">
                <div className="content-inner">
                  <h3 style={{ color: "#3749E9" }}>
                    {data[0]?.hero_subtitle}
                    <span className="color--secondary-color">
                      <i className="ms-2 fa-solid fa-wifi"></i>
                    </span>
                  </h3>
                  <h1 className="heading">
                    {/* Defind, Coll<span>ect</span> and Sell Sup<span>er</span>{" "}
                    Rate NFT */}
                    {data[0]?.hero_title}
                    <img src={heroTextBg} alt="Binasea" />
                  </h1>
                  <p className="sub-heading">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer nec odio. Praesent libero. Sed cursus ante dapibus
                    diam. */}
                    {data[0]?.hero_text}
                  </p>
                  <div className="btn-slider ">
                    <Link to={isAuthenticated ? `/${data[0]?.hero_btnUrl}`: '/login'} className="tf-button style-2">
                      {data[0]?.hero_btnText}
                    </Link>
                    <div onClick={handleDownload} className="tf-button style-3">
                      Download White Paper
                    </div>
                  </div>
                </div>
                <div className="image">
                  <div className="img-slider-main ani5">
                    {/* <img
                      src={
                        "https://binaseareact.vercel.app/static/media/slider-1.659aee2f403e034a0ec9.jpg"
                      }
                      alt="Binasea"
                    /> */}
                    <img
                      src={`${global.backendUrl}/nodeassets/${data[0]?.hero_image}`}
                      alt="Ants Protocol"
                      style={{ width: '450px', height: "500px", objectFit: 'cover' }}
                    />
                  </div>
                  <img
                    src={beesimg}
                    alt="Binasea"
                    className="img-slider-2 ani4"
                  />
                  <img
                    src={beesimg}
                    alt="Binasea"
                    className="img-slider-3 ani5"
                  />

                  <div className="current-bid pt-3" style={{ height: '105px', width: '120px', cursor: 'pointer' }} onClick={handleShow}>
                    <svg
                      viewBox="0 0 100 100"
                    // style={{
                    //   background: '#0099FF',
                    // }}
                    >
                      <defs>
                        <path
                          id="circle"
                          d="
                            M 50, 50
                            m -37, 0
                            a 37,37 0 1,1 74,0
                            a 37,37 0 1,1 -74,0"
                        />
                      </defs>
                      <text>
                        <textPath fill="#000" xlinkHref="#circle">
                          How to Vote * How to Vote
                        </textPath>
                      </text>
                    </svg>
                    <span style={{ position: 'absolute', top: "40%", right: "44%" }}>
                      <i className="fa-solid text-white fs-5 fa-play text-danger"></i>
                    </span>
                    {/* <div className="title">How To Vote</div> */}
                    {/* <div className="price">{"1.56 wETH"}</div> */}
                  </div>

                  <Modal show={show} onHide={handleClose}>
                    <div class="modal-header position-absolute w-100" style={{ zIndex: 1, borderBottom: 0 }}>
                      <button type="button" class="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
                    </div>
                    <div class="modal-body p-0 bg-dark">
                      <iframe src={data2} className="w-100" height="300" title="Iframe Example"></iframe>
                    </div>
                  </Modal>


                  {/* <div className="card-infor ani5">
                    <img src={avatarimg} alt="Binasea" />
                    <div className="inner ">
                      <h6 className="name">{"“The Monkey sad ”"}</h6>
                      <p className="author">{"@SolvadorDali"}</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner01;

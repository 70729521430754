
import avt1 from '../images/avtimg.png';

const dataCollection = [
    {
        id: 1,
        img1: "https://binaseareact.vercel.app/static/media/collection1.9a5c8e077c8cad544f15.jpg",
        img2: "https://binaseareact.vercel.app/static/media/collection11.1b5572aa9a566bcd7c8b.jpg",
        img3: "https://binaseareact.vercel.app/static/media/collection9.222d99affd4e5f506b9b.jpg",
        avt: avt1,
        name: 'Moonbirds',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 2,
        img1: "https://binaseareact.vercel.app/static/media/collection4.f88fbcc576e33f2b06bf.jpg",
        img2: "https://binaseareact.vercel.app/static/media/collection9.222d99affd4e5f506b9b.jpg",
        img3: "https://binaseareact.vercel.app/static/media/collection11.1b5572aa9a566bcd7c8b.jpg",
        avt: avt1,
        name: 'Cassandra',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 3,
        img1: "https://binaseareact.vercel.app/static/media/collection1.9a5c8e077c8cad544f15.jpg",
        img2: "https://binaseareact.vercel.app/static/media/collection11.1b5572aa9a566bcd7c8b.jpg",
        img3: "https://binaseareact.vercel.app/static/media/collection9.222d99affd4e5f506b9b.jpg",
        avt: avt1,
        name: 'Quinn Herrera',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 4,
        img1: "https://binaseareact.vercel.app/static/media/collection4.f88fbcc576e33f2b06bf.jpg",
        img2: "https://binaseareact.vercel.app/static/media/collection9.222d99affd4e5f506b9b.jpg",
        img3: "https://binaseareact.vercel.app/static/media/collection11.1b5572aa9a566bcd7c8b.jpg",
        avt: avt1,
        name: 'Humbert Watts',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },


    {
        id: 5,
        img1: "https://binaseareact.vercel.app/static/media/collection1.9a5c8e077c8cad544f15.jpg",
        img2: "https://binaseareact.vercel.app/static/media/collection11.1b5572aa9a566bcd7c8b.jpg",
        img3: "https://binaseareact.vercel.app/static/media/collection9.222d99affd4e5f506b9b.jpg",
        avt: avt1,
        title: 'Art Collection',
        name: '@Grient Phahn',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 6,
        img1: "https://binaseareact.vercel.app/static/media/collection4.f88fbcc576e33f2b06bf.jpg",
        img2: "https://binaseareact.vercel.app/static/media/collection9.222d99affd4e5f506b9b.jpg",
        img3: "https://binaseareact.vercel.app/static/media/collection11.1b5572aa9a566bcd7c8b.jpg",
        avt: avt1,
        title: 'Game Collection',
        name: '@Grient Phahn',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },
    {
        id: 7,
        img1: "https://binaseareact.vercel.app/static/media/collection1.9a5c8e077c8cad544f15.jpg",
        img2: "https://binaseareact.vercel.app/static/media/collection11.1b5572aa9a566bcd7c8b.jpg",
        img3: "https://binaseareact.vercel.app/static/media/collection9.222d99affd4e5f506b9b.jpg",
        avt: avt1,
        title: 'Art Collection',
        name: '@Grient Phahn',
        count: '26 Items',
        price : '33.2 wETH',
        pricesale: '$ 92,025'
    },



   
]

export default dataCollection;
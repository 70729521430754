import seller from "../images/seller.png";

const dataExplore = [
  {
    id: 1,
    img: "https://binaseareact.vercel.app/static/media/product3.0128fdacd686cc9cb997.jpg",
    avt: seller,
    title: "Giulia Glur #32",
    create: "@SolvadorDali",
    price: "1.56wETH",
    avt1: seller,
    avt2: seller,
    avt3: seller,
  },
  {
    id: 2,
    img: "https://binaseareact.vercel.app/static/media/product44.b3a7fd3cdc9ebbc9f828.jpg",
    avt: seller,
    title: "Giulia Glur #32",
    create: "@SolvadorDali",
    price: "1.56wETH",
    avt1: seller,
    avt2: seller,
    avt3: seller,
  },
  {
    id: 3,
    img: "https://binaseareact.vercel.app/static/media/product45.95ca2a6202319c355d31.jpg",
    avt: seller,
    title: "Archetype #588",
    create: "@SolvadorDali",
    price: "1.56wETH",
    avt1: seller,
    avt2: seller,
    avt3: seller,
  },
  {
    id: 4,
    img: "https://binaseareact.vercel.app/static/media/product46.a439a84dcb85ee071424.jpg",
    avt: seller,
    title: "3DPunks #070",
    create: "@SolvadorDali",
    price: "1.56wETH",
    avt1: seller,
    avt2: seller,
    avt3: seller,
  },
  {
    id: 5,
    img: "https://binaseareact.vercel.app/static/media/product48.f664636b16a2bd583bd7.jpg",
    avt: seller,
    title: "Angelic Nyan Cat",
    create: "@SolvadorDali",
    price: "1.56wETH",
    avt1: seller,
    avt2: seller,
    avt3: seller,
  },
  {
    id: 6,
    img: "https://binaseareact.vercel.app/static/media/product49.1fb5941f0eebc0a0fc48.jpg",
    avt: seller,
    title: "Sweet Baby #1",
    create: "@SolvadorDali",
    price: "1.56wETH",
    avt1: seller,
    avt2: seller,
    avt3: seller,
  },
  {
    id: 7,
    img: "https://binaseareact.vercel.app/static/media/product50.c83637d6b4a0635417d7.jpg",
    avt: seller,
    title: "Sweet Baby #1",
    create: "@SolvadorDali",
    price: "1.56wETH",
    avt1: seller,
    avt2: seller,
    avt3: seller,
  },
  {
    id: 8,
    img: "https://binaseareact.vercel.app/static/media/product47.65583cd92ae6ad31dbb3.jpg",
    avt: seller,
    title: "Sweet Baby #1",
    create: "@SolvadorDali",
    price: "1.56wETH",
    avt1: seller,
    avt2: seller,
    avt3: seller,
  },
];

export default dataExplore;
